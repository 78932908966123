// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-agile-js": () => import("./../src/pages/agile.js" /* webpackChunkName: "component---src-pages-agile-js" */),
  "component---src-pages-angular-js": () => import("./../src/pages/angular.js" /* webpackChunkName: "component---src-pages-angular-js" */),
  "component---src-pages-azure-js": () => import("./../src/pages/azure.js" /* webpackChunkName: "component---src-pages-azure-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-identityserver-js": () => import("./../src/pages/identityserver.js" /* webpackChunkName: "component---src-pages-identityserver-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-page-2-tsx": () => import("./../src/pages/page-2.tsx" /* webpackChunkName: "component---src-pages-page-2-tsx" */),
  "component---src-pages-react-js": () => import("./../src/pages/react.js" /* webpackChunkName: "component---src-pages-react-js" */),
  "component---src-pages-sharepoint-js": () => import("./../src/pages/sharepoint.js" /* webpackChunkName: "component---src-pages-sharepoint-js" */),
  "component---src-pages-technology-js": () => import("./../src/pages/technology.js" /* webpackChunkName: "component---src-pages-technology-js" */),
  "component---src-templates-index-js": () => import("./../src/templates/index.js" /* webpackChunkName: "component---src-templates-index-js" */)
}

