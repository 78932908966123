export default {
    text: '#000',
    background: '#fff',
    primary: '#e63b19',
    secondary: '#c70d3a',
    sidebar: '#eee',
    borderColor: 'rgba(0, 0, 0, 0.15)',
    modes: {
      dark: {
        text: '#fff',
        background: '#182952',
        primary: '#f638dc',
        secondary: '#ff7976',
        sidebar: '#101d3c',
        borderColor: 'rgba(255, 255, 255, 0.15)'
      },
      cool: {
        text: '#fff',
        background: '#05386b',
        primary: '#5cdb95',
        secondary: '#bef992',
        sidebar: '#052e56',
        borderColor: 'rgba(255, 255, 255, 0.15)'
      },
      deep: {
        text: '#fff',
        background: 'hsl(230,25%,18%)',
        primary: 'hsl(260, 100%, 80%)',
        secondary: 'hsl(290, 100%, 80%)',
        sidebar: 'hsla(230, 20%, 0%, 20%)',
        borderColor: 'rgba(255, 255, 255, 0.15)'
      }
    }
  };
  